<template>
  <div>
    <div class="static-container">
      <div class="static-title">{{ $trans('联系我们', '联系我们') }}</div>
      <div class="static-p" v-for="(i, index) in relation" :key="index">
        {{ $trans(i, i) }}
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/layout/Footer.vue'
import { relation } from '@/util/resource/static.jsx'
export default {
  components: {
    Footer,
  },
  computed: {
    relation() {
      return relation()
    },
  },
}
</script>
<style scoped>
.static-container {
  width: 800px;
  padding: 100px 0;
  margin: 0 auto;
}
.static-title {
  font-family: 'myCoolFontMedium';
  font-size: 20px;
  margin-bottom: 60px;
}
.static-p {
  font-family: 'myCoolFontMedium';
  line-height: 34px;
}
</style>
